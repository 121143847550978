import React from "react";
import serviceS1 from "@/images/rice1.png";
import serviceS2 from "@/images/s2.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <h2>Premium Rice Exporting</h2>
      <p>
      At Palm Desert Trading, we excel in the global rice market, offering a wide range of high-quality rice varieties sourced from trusted producers around the world. Our expertise in rice trading ensures that we select the finest grains to meet the diverse needs of international buyers. We manage the entire supply chain—from sourcing and quality control to logistics and delivery—ensuring that our customers receive consistent, top-grade rice with every shipment.
<br /><br />
Our commitment to excellence involves rigorous quality assessments to meet international standards, as well as maintaining strong relationships with rice growers and suppliers. This allows us to offer competitive pricing and reliable supply, supporting our clients with timely deliveries and exceptional service. Through sustainable practices and innovative solutions, Palm Desert Trading contributes to the global food supply, enhancing food security and promoting quality in every grain we export.
      </p>
      <img src={serviceS1} alt="" />
    
      <h2>Global Staple</h2>
      <p><h4>Varieties of Rice</h4>
      •	White Rice: The most popular type in Jordan, with the husk, bran, and germ removed, resulting in a smooth, starchy grain that is a staple in many Jordanian households.
•	Brown Rice: Less processed, retaining its bran and germ, offering a nuttier flavor and higher nutritional value, increasingly sought after for health-conscious consumers.
•	Basmati Rice: Renowned for its long, slender grains and aromatic flavor, this variety is ideal for traditional Middle Eastern dishes and is favored in Jordanian cuisine.
•	Jasmine Rice: A fragrant, long-grain rice from Thailand, with a slightly sticky texture, perfect for a range of Asian-inspired dishes popular in Jordan.
•	Arborio Rice: Short-grain rice known for its creamy texture when cooked, used in risottos and gourmet dishes that cater to Jordan's growing culinary diversity.
•	Wild Rice: Although less common, its chewy texture and nutty flavor make it an exciting option for gourmet dishes in Jordan.

<br/><h4>Growing Regions</h4>
•	Asia: Major producers like China, India, and Thailand supply a significant portion of the rice consumed in Jordan, providing a diverse range of varieties.
•	South America: Countries like Brazil and Argentina contribute to the global supply, offering both conventional and specialty rice that can reach Jordanian markets.
•	United States: While not a primary source for Jordan, U.S. rice from states like Arkansas and California can occasionally supplement supply needs.

<br/><h4>Cultivation Process</h4>
•	Planting: Rice is cultivated in flooded fields known as paddies, a method that can be performed manually or with advanced machinery.
•	Growing: Requires ample water and a warm climate, conditions that are ideal for the major rice-producing regions that supply Jordan.
•	Harvesting: Mature rice grains are harvested, dried, and milled to remove the husk, resulting in the edible rice varieties that reach Jordanian consumers.

<br/><h4>Nutritional Value</h4>
•	Carbohydrates: Rice is a major energy source due to its high carbohydrate content, making it an essential part of Jordanian diets.
•	Proteins: Provides a moderate amount of protein, complementing other protein sources in Jordanian meals.
•	Vitamins and Minerals: Brown rice offers more vitamins and minerals compared to white rice, supporting a balanced diet for health-conscious Jordanians.

<br/><h4>Economic and Cultural Significance</h4>
•	Economic Impact: Rice is crucial for food security and agricultural economies worldwide, including Jordan, where it is a staple food.
•	Cultural Importance: Integral to Jordanian cuisine, rice features prominently in traditional dishes and festive meals, reflecting its cultural significance.
<br/><h4>Trade and Export</h4>
•	Global Market: Jordan imports rice from key exporters such as Thailand, India, and Pakistan to meet local demand.
•	Quality Standards: Ensuring adherence to international quality standards and grading systems is essential for meeting Jordanian import regulations and consumer expectations.
</p>
      </div>
  );
};

export default ServiceDetailsContent;
