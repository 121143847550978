import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="#">Premium Rice Exporting</a>
            </li>
            <li>
              <a href="#">Global Staple</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Place Your Order Today</h4>
          <p>
          Elevate your rice sourcing and trading experience with unmatched quality and precision. Embrace the richness of top-tier rice varieties, the benefits of strategic sourcing, and the reliability of our dedicated service. Partner with Palm Desert Trading today and embark on a journey of ensuring premium quality and consistent supply. Discover the Excellence in Rice Trading – Where Quality Meets Expertise.
          </p>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
